import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponentMode, BaseDatatableComponent, ConfirmDialogComponent, IApiListQueryParameter, IDataTableParameters } from "@impacgroup/angular-baselib";
import { PostcodeUsersService } from "./postcodeusers.service";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { PostcodeAreaDetails, PostcodeAreaDetailsRow, UserDetails } from "src/app/api-models/PostcodeUser";
import { PermissionService } from "@impacgroup/angular-oauth-base";
import { PostcodeUsersMainComponent } from "./postcodeusers.main.component";

@Component({
    selector: "app-postcodeuserdetail",
    templateUrl: "./postcodeuserdetail.component.html",
    styleUrls: ["postcodeuserdetail.component.scss"]
})
export class PostcodeUserDetailComponent extends BaseDatatableComponent {
    public canAddPostcodes = false;
    public canDeletePostcodes = false;
    public postcodeUser: UserDetails = null;

    public assignedPostcodes: PostcodeAreaDetails[] = [];
    public unassignedPostcodes: PostcodeAreaDetails[] = [];

    public UTCDATEFORMAT: string = "";
    private queryParams: IApiListQueryParameter;

    private userId: string = null;

    constructor(
        private route: ActivatedRoute,
        private permissionService: PermissionService,
        private postcodeUsersService: PostcodeUsersService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        private modalService: BsModalService,
        parent: PostcodeUsersMainComponent
    ) {
        super(translateService, {
            stateSaveMode: postcodeUsersService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_postcodeusers_list"
        });

        this.dtOptions.order = [[1, "asc"]];
        this.UTCDATEFORMAT = this.postcodeUsersService.UTCDATEFORMAT;

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.userId = params.id;
                if (this.userId) {
                    if (permissionService.hasPermission("POSTCODEUSERS.ADD") || permissionService.hasPermission("POSTCODEUSERS.DELETE")) {
                        this.initEditMode();
                    } else {
                        this.initViewMode();
                    }
                } else {
                    this._location.back();
                }
            })
        );
    }
    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            this.queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);
            const sub = this.postcodeUsersService.read(this.userId, this.queryParams).subscribe((result) => {
                this.postcodeUser = result.userDetails;
                this.assignedPostcodes = result.assignedPostcodes ?? [];
                this.unassignedPostcodes = result.unassignedPostcodes ?? [];

                callback({
                    recordsTotal: result.totalUnassignedPostcodes,
                    recordsFiltered: result.countUnassignedPostcodes,
                    data: []
                });
            });

            this.subscriptions.push(sub);
        };
    }

    _dataTableColums() {
        return [
            { data: "actions", orderable: false },
            { data: "postcodeStart", orderable: true },
            { data: "postcodeEnd", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true }
        ];
    }

    initViewMode() {
        this.MODE = BaseComponentMode.VIEW;
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;
        this.canAddPostcodes = this.permissionService.hasPermission("POSTCODEUSERS.ADD");
        this.canDeletePostcodes = this.permissionService.hasPermission("POSTCODEUSERS.DELETE");
    }

    back() {
        this._location.back();
    }

    changeUserCheckbox(event, postcode: PostcodeAreaDetailsRow) {
        const newCheckboxState = event.target.checked;
        // Prevent toggling checkbox until the modal is answered (or not)
        event.target.checked = !newCheckboxState;
        postcode.checked = !newCheckboxState;
        if (newCheckboxState === true) {
            this.assignUserTo(postcode);
        } else {
            this.unassignUserFrom(postcode);
        }
    }

    private unassignUserFrom(postcode: PostcodeAreaDetailsRow) {
        const initialState = {
            title: this.translateService.instant("postcodeusers.detail.assignedPostcodes.delete.title"),
            message: this.translateService.instant("postcodeusers.detail.assignedPostcodes.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.postcodeUsersService.delete(this.userId, postcode._id, this.queryParams).subscribe((result) => {
                    this.postcodeUser = result.userDetails;
                    this.assignedPostcodes = result.assignedPostcodes ?? [];
                    this.unassignedPostcodes = result.unassignedPostcodes ?? [];

                    this.alertService.addSuccess(this.translateService.instant("postcodeusers.detail.assignedPostcodes.delete.success"));
                })
            );
        };
    }

    private assignUserTo(postcode) {
        this.subscriptions.push(
            this.postcodeUsersService.create(this.userId, postcode._id, this.queryParams).subscribe((result) => {
                this.postcodeUser = result.userDetails;
                this.assignedPostcodes = result.assignedPostcodes ?? [];
                this.unassignedPostcodes = result.unassignedPostcodes ?? [];

                this.alertService.addSuccess(this.translateService.instant("postcodeusers.detail.assignedPostcodes.add.success"));
            })
        );
    }
}
