import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import * as JSZip from "jszip";
import * as moment from "moment";
import { BsModalService } from "ngx-bootstrap/modal";
import { ChecklistsService, IExportChecklistResultsQueryParams } from "./checklists.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, IApiListQueryParameter, IDataTableParameters } from "@impacgroup/angular-baselib";
import { Checklist, Status, DistributorObj, DistributorStatus, DistributorStatusArray, DistributorAccessible } from "../../api-models/Checklist";
import { ChecklistsMainComponent } from "./checklists.main.component";
import { saveAs } from "file-saver";
import { PermissionService } from "@impacgroup/angular-oauth-base";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpHeaders, HttpResponse } from "@angular/common/http";

export const MaximalTemplateNumbers = 2;
export const TemplateNumberArray: number[] = Array.from({ length: MaximalTemplateNumbers }, (_, i) => i + 1);

@Component({
    selector: "app-checklistoverview",
    templateUrl: "./checklistoverview.component.html",
    styleUrls: ["checklistoverview.component.scss"]
})
export class ChecklistOverviewComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";
    public status = Status;
    public distributorAccessible = DistributorAccessible;

    @ViewChild(DataTableDirective, { static: false })
    datatableElement: DataTableDirective;
    rows: Checklist[] = [];

    distributors: Array<DistributorObj> = [];
    selectedDistributor?: DistributorObj;

    getQueryParamDistributorId = undefined;

    selectedRows: {
        [key: string]: boolean;
    } = {};
    downloadingMultipleChecklistsInProgress: boolean = false;
    zippingMultipleChecklistsInProgress: boolean = false;

    showAsm: boolean = false;
    showAccessUnknown: boolean = true;
    showAccessible: boolean = true;
    showNotAccessible: boolean = true;
    isStatus: Partial<Record<DistributorStatus, boolean>>;

    statusOptions = DistributorStatusArray;

    isTemplateNumber: Partial<Record<number, boolean>>;
    templateNumbers = TemplateNumberArray;
    chosenSingleTemplateNumber: number = 0;

    private queryParams: IExportChecklistResultsQueryParams;

    constructor(
        private checklistsService: ChecklistsService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        private permissionService: PermissionService,
        private parent: ChecklistsMainComponent
    ) {
        super(translateService, {
            stateSaveMode: checklistsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_checklistoverview_list"
        });

        this.dtOptions.order = [[6, "desc"]];
        this.UTCDATEFORMAT = this.checklistsService.UTCDATEFORMAT;

        // Check all status checkboxes
        this.isStatus = DistributorStatusArray.reduce((acc, cur) => Object.assign(acc, { [cur]: true }), {});

        // Check all template number checkboxes
        this.isTemplateNumber = TemplateNumberArray.reduce((acc, cur) => Object.assign(acc, { [cur]: true }), {});
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams?.mode || this.route.snapshot.queryParams?.date || this.route.snapshot.queryParams?.userFilter) {
            this.parent.visitReportParams = {
                mode: this.route.snapshot.queryParams?.mode,
                date: this.route.snapshot.queryParams?.date,
                userFilter: this.route.snapshot.queryParams?.userFilter
            };

            // AYC-143: Also show "approval confirmed" checklists when coming from "visit report"
            this.parent.showApprovalConfirmed = true;
        }

        this.getQueryParamDistributorId = this.route.snapshot.queryParams["distributorId"];

        this.subscriptions.push(
            this.checklistsService.getDistributors().subscribe((result) => {
                this.distributors = result;

                const getParamDistributorIndex = this.distributors.findIndex((distributor) => distributor._id === this.getQueryParamDistributorId);
                if (getParamDistributorIndex) {
                    this.selectedDistributor = this.distributors[getParamDistributorIndex];
                    delete this.getQueryParamDistributorId;

                    // Remove passed parameter
                    this.router.navigate([], { relativeTo: this.route, queryParams: { distributorId: null }, replaceUrl: true });
                }
            })
        );
    }

    onChangeFilter() {
        const chosenTemplateNumbers = Object.entries(this.isTemplateNumber).filter((entry) => entry[1] === true);
        this.chosenSingleTemplateNumber = chosenTemplateNumbers.length === 1 ? parseInt(chosenTemplateNumbers[0][0], 10) : 0;

        this.reDrawTable();
    }

    reDrawTable() {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            this.queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            if (typeof this.selectedDistributor !== "undefined" && this.selectedDistributor !== null) {
                this.queryParams["distributorId"] = this.selectedDistributor._id;
            } else if (typeof this.getQueryParamDistributorId !== "undefined") {
                this.queryParams["distributorId"] = this.getQueryParamDistributorId;
            }

            this.queryParams["showDifferenceDetected"] = this.parent.showDifferenceDetected ? "1" : "0";
            this.queryParams["showNoDifferenceDetected"] = this.parent.showNoDifferenceDetected ? "1" : "0";
            this.queryParams["showWarningNoticesDetected"] = this.parent.showWarningNoticesDetected ? "1" : "0";
            this.queryParams["showWarningNoticesUnknown"] = this.parent.showWarningNoticesUnknown ? "1" : "0";
            this.queryParams["showNoWarningNoticesDetected"] = this.parent.showNoWarningNoticesDetected ? "1" : "0";
            this.queryParams["showNeutral"] = this.parent.showNeutral ? "1" : "0";
            this.queryParams["showMarked"] = this.parent.showMarked ? "1" : "0";
            this.queryParams["showApproved"] = this.parent.showApproved ? "1" : "0";
            this.queryParams["showApprovalConfirmed"] = this.parent.showApprovalConfirmed ? "1" : "0";

            this.queryParams["showAccessUnknown"] = this.showAccessUnknown ? "1" : "0";
            this.queryParams["showAccessible"] = this.showAccessible ? "1" : "0";
            this.queryParams["showNotAccessible"] = this.showNotAccessible ? "1" : "0";

            this.queryParams["date"] = this.parent.visitReportParams?.date ?? "";
            this.queryParams["mode"] = this.parent.visitReportParams?.mode ?? "";
            this.queryParams["userFilter"] = this.parent.visitReportParams?.userFilter ?? "";

            // Get only named values, if checkbox is checked
            const statusArray = Object.entries(this.isStatus).reduce((acc: DistributorStatus[], cur: [DistributorStatus, boolean]) => acc.concat(cur[1] ? cur[0] : undefined), []);
            this.queryParams["status[]"] = statusArray.length > 0 ? statusArray : [];

            // Get only named values, if checkbox is checked
            const templateNumberArray = Object.entries(this.isTemplateNumber).reduce((acc: DistributorStatus[], cur: [DistributorStatus, boolean]) => acc.concat(cur[1] ? cur[0] : undefined), []);
            this.queryParams["templateNumber[]"] = templateNumberArray.length > 0 ? templateNumberArray : [];

            this.subscriptions.push(
                this.checklistsService.checklistOverview(this.queryParams).subscribe((result) => {
                    this.rows = result.list;

                    this.rows.forEach((row) => {
                        if (this.selectedRows[String(row._id)] === undefined) {
                            this.selectedRows[String(row._id)] = false;
                        }
                    });

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "selection", orderable: false },
            { data: "status", orderable: false },
            { data: "distributor", orderable: false },
            { data: "distributorObj.status", orderable: true },
            { data: "distributorObj.asm", orderable: true },
            { data: "user", orderable: false },
            { data: "visited", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    downloadChecklist(id: string) {
        // TODO: AYC-130 - VO + Händlername + Erstellungsdatum der Checkliste
        this.subscriptions.push(
            this.checklistsService.downloadChecklistPDF(id).subscribe((response: HttpResponse<Blob>) => {
                saveAs(response.body, this.createChecklistFilenameFromHeaders(response.headers));
            })
        );
    }

    markChecklist(id: string) {
        this.setMarkChecklist(id, true);
    }

    unmarkChecklist(id: string) {
        this.setMarkChecklist(id, false);
    }

    approveChecklist(id: string) {
        this.setApproveChecklist(id, true);
    }

    unapproveChecklist(id: string) {
        this.setApproveChecklist(id, false);
    }

    confirmChecklist(id: string) {
        this.setConfirmChecklist(id, true);
    }

    unconfirmChecklist(id: string) {
        this.setConfirmChecklist(id, false);
    }

    private setMarkChecklist(id: string, state: boolean) {
        if (this.permissionService.hasPermission("CHECKLIST.UPDATE.MARK")) {
            this.checklistsService.setMarkStateOnChecklist(id, state).subscribe((result) => {
                this.rerenderDatatable();
            });
        } else {
            this.alertService.addCustomErrorByI18nId(this.translateService.instant("checklists.overview.list.error"));
        }
    }

    private setApproveChecklist(id: string, state: boolean) {
        const title = state ? "checklists.overview.list.approve.title" : "checklists.overview.list.unapprove.title";
        const message = state ? "checklists.overview.list.approve.message" : "checklists.overview.list.unapprove.message";
        const success = state ? "checklists.overview.list.approve.success" : "checklists.overview.list.unapprove.success";

        const initialState = {
            title: this.translateService.instant(title),
            message: this.translateService.instant(message),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.checklistsService.setApproveStateOnChecklist(id, state).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant(success));
                    this.rerenderDatatable();
                })
            );
        };
    }

    private setConfirmChecklist(id: string, state: boolean) {
        const title = state ? "checklists.overview.list.confirm.title" : "checklists.overview.list.unconfirm.title";
        const message = state ? "checklists.overview.list.confirm.message" : "checklists.overview.list.unconfirm.message";
        const success = state ? "checklists.overview.list.confirm.success" : "checklists.overview.list.unconfirm.success";

        const initialState = {
            title: this.translateService.instant(title),
            message: this.translateService.instant(message),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.checklistsService.setConfirmStateOnChecklist(id, state).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant(success));
                    this.rerenderDatatable();
                })
            );
        };
    }

    viewChecklist(id: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    downloadChecklistResultsCSV() {
        this.subscriptions.push(
            this.checklistsService.exportChecklistResults({ "templateNumber[]": this.queryParams["templateNumber[]"] }).subscribe((orderItemsCSVBlob) => {
                const fileName = `${moment().utc().format("YYYY-MM-DDTHH-mm-ss")}_checklist_results.csv`;
                saveAs(orderItemsCSVBlob, fileName);
            })
        );
    }

    downloadFilteredChecklistResultsCSV() {
        const filteredQueryParams = { ...this.queryParams };

        // Omit page and limit for CSV export
        delete filteredQueryParams.page;
        delete filteredQueryParams.limit;

        this.subscriptions.push(
            this.checklistsService.exportChecklistResults(filteredQueryParams).subscribe((orderItemsCSVBlob) => {
                const fileName = `${moment().utc().format("YYYY-MM-DDTHH-mm-ss")}_filtered_checklist_results.csv`;
                saveAs(orderItemsCSVBlob, fileName);
            })
        );
    }

    isAtLeastOneChecklistSelected() {
        return Object.keys(this.selectedRows)
            .map((id) => {
                return this.selectedRows[id] === true;
            })
            .find((bool) => bool === true);
    }

    markSelectedChecklists() {
        const observables: Observable<void>[] = [];

        Object.keys(this.selectedRows).forEach((id) => {
            if (this.selectedRows[id] === true) {
                const observable = this.checklistsService.setMarkStateOnChecklist(id, true);
                observables.push(observable);

                this.subscriptions.push(observable.subscribe((result) => {}));
                this.selectedRows[id] = false;
            }
        });

        forkJoin(observables).subscribe(() => {
            this.rerenderDatatable();
        });
    }

    approveSelectedChecklists() {
        const title = "checklists.overview.list.approveSelected.title";
        const message = "checklists.overview.list.approveSelected.message";
        const success = "checklists.overview.list.approveSelected.success";

        const initialState = {
            title: this.translateService.instant(title),
            message: this.translateService.instant(message),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            const observables: Observable<void>[] = [];

            Object.keys(this.selectedRows).forEach((id) => {
                if (this.selectedRows[id] === true) {
                    const observable = this.checklistsService.setApproveStateOnChecklist(id, true);
                    observables.push(observable);

                    this.subscriptions.push(observable.subscribe((result) => {}));
                    this.selectedRows[id] = false;
                }
            });

            forkJoin(observables).subscribe(() => {
                this.rerenderDatatable();
                this.alertService.addSuccess(this.translateService.instant(success));
            });
        };
    }

    confirmSelectedChecklists() {
        const title = "checklists.overview.list.confirmSelected.title";
        const message = "checklists.overview.list.confirmSelected.message";
        const success = "checklists.overview.list.confirmSelected.success";

        const initialState = {
            title: this.translateService.instant(title),
            message: this.translateService.instant(message),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            const observables: Observable<void>[] = [];

            Object.keys(this.selectedRows).forEach((id) => {
                if (this.selectedRows[id] === true) {
                    const observable = this.checklistsService.setConfirmStateOnChecklist(id, true);
                    observables.push(observable);

                    this.subscriptions.push(observable.subscribe((result) => {}));
                    this.selectedRows[id] = false;
                }
            });

            forkJoin(observables).subscribe(() => {
                this.reDrawTable();
                this.alertService.addSuccess(this.translateService.instant(success));
            });
        };
    }

    downloadSelectedChecklists() {
        if (this.downloadingMultipleChecklistsInProgress || this.zippingMultipleChecklistsInProgress) {
            return;
        }

        this.downloadingMultipleChecklistsInProgress = true;

        const checklistIds = [];

        Object.keys(this.selectedRows).forEach((id) => {
            if (this.selectedRows[id] === true) {
                checklistIds.push(id);
            }
        });

        const observables = checklistIds.map((checklistId) =>
            this.checklistsService.downloadChecklistPDF(checklistId).pipe(
                map((response: HttpResponse<Blob>) => {
                    return {
                        id: checklistId,
                        file: response.body,
                        name: this.createChecklistFilenameFromHeaders(response.headers)
                    };
                })
            )
        );

        const forkJoinSubscription = forkJoin(observables).subscribe((pdfFiles) => {
            this.zippingMultipleChecklistsInProgress = true;
            this.downloadingMultipleChecklistsInProgress = false;
            const jsZip = new JSZip();

            pdfFiles.forEach((pdfFile) => {
                jsZip.file(pdfFile.name, pdfFile.file);
            });

            jsZip.generateAsync({ type: "blob" }).then((content) => {
                // see FileSaver.js
                saveAs(content, `${moment().utc().format("YYYY-MM-DDTHH-mm-ss")}_checklists.zip`);
                this.zippingMultipleChecklistsInProgress = false;
            });
        });

        this.subscriptions.push(forkJoinSubscription);
    }

    isDownloadSelectedChecklistsProcessing() {
        return this.downloadingMultipleChecklistsInProgress || this.zippingMultipleChecklistsInProgress;
    }

    selectAll() {
        this.rows.forEach((row) => {
            this.selectedRows[row._id] = true;
        });
    }

    deselectAll() {
        this.rows.forEach((row) => {
            this.selectedRows[row._id] = false;
        });
    }

    onStatusSelected() {
        this.onChangeFilter();
    }

    private createChecklistFilenameFromHeaders(headers: HttpHeaders) {
        const vo = headers.get("x-ayyildiz-vo");
        const visitDate = headers.get("x-ayyildiz-visit-date");
        const distributorName = headers.get("x-ayyildiz-distributor-name");

        return vo + "_" + distributorName.replace(/ /g, "_") + "_" + visitDate + ".pdf";
    }
}
