<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'VIEW'">{{ "postcodeusers.detail.view.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "postcodeusers.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="postcodeUser" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "postcodeusers.props.email" | translate }}</label>
                        <input name="email" type="text" class="form-control" [(ngModel)]="postcodeUser.email" #email="ngModel" disabled />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "postcodeusers.props.firstname" | translate }}</label>
                        <input name="firstname" type="text" class="form-control" [(ngModel)]="postcodeUser.firstname" #firstname="ngModel" disabled />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "postcodeusers.props.lastname" | translate }}</label>
                        <input name="lastname" type="text" class="form-control" [(ngModel)]="postcodeUser.lastname" #lastname="ngModel" disabled />
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="card" *ngIf="assignedPostcodes.length > 0">
        <div class="card-body">
            <h4 class="card-title">{{ "postcodeusers.detail.assignedPostcodes.title" | translate }}</h4>

            <table class="table mb-0">
                <thead>
                    <tr>
                        <th></th>
                        <th>{{ "postcodeusers.detail.assignedPostcodes.props.postcodeStart" | translate }}</th>
                        <th>{{ "postcodeusers.detail.assignedPostcodes.props.postcodeEnd" | translate }}</th>
                        <th id="created">{{ "postcodeareas.props.created" | translate }}</th>
                        <th id="updated">{{ "postcodeareas.props.updated" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let assignedPostcode of assignedPostcodes">
                        <td>
                            <div class="checkbox">
                                <input type="checkbox" id="checked_{{ assignedPostcode._id }}" [checked]="true" (change)="changeUserCheckbox($event, assignedPostcode)" [disabled]="!canAddPostcodes" />
                                <label class="checkbox__label" for="checked_{{ assignedPostcode._id }}"></label>
                            </div>
                        </td>
                        <td>{{ assignedPostcode?.postcodeStart }}</td>
                        <td>{{ assignedPostcode?.postcodeEnd }}</td>
                        <td>{{ assignedPostcode?.created | date : UTCDATEFORMAT : "+0" }}</td>
                        <td>{{ assignedPostcode?.updated | date : UTCDATEFORMAT : "+0" }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <h4 class="card-title">{{ "postcodeusers.detail.unassignedPostcodes.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="actions"></th>
                        <th id="name">{{ "postcodeareas.props.postcodeStart" | translate }}</th>
                        <th id="link">{{ "postcodeareas.props.postcodeEnd" | translate }}</th>
                        <th id="created">{{ "postcodeareas.props.created" | translate }}</th>
                        <th id="updated">{{ "postcodeareas.props.updated" | translate }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="unassignedPostcodes?.length != 0">
                    <tr *ngFor="let row of unassignedPostcodes">
                        <td>
                            <div class="checkbox">
                                <input type="checkbox" id="checked_{{ row._id }}" [checked]="false" (change)="changeUserCheckbox($event, row)" [disabled]="!canDeletePostcodes" />
                                <label class="checkbox__label" for="checked_{{ row._id }}"></label>
                            </div>
                        </td>
                        <td>{{ row.postcodeStart }}</td>
                        <td>{{ row.postcodeEnd }}</td>
                        <td>{{ row.created | date : UTCDATEFORMAT : "+0" }}</td>
                        <td>{{ row.updated | date : UTCDATEFORMAT : "+0" }}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="unassignedPostcodes?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
