import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable, OperatorFunction } from "rxjs";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { PostcodeUsersRepository } from "./postcodeusers.repository";
import { PostcodeUser, PostcodeUserDetail } from "src/app/api-models/PostcodeUser";
import { PostcodeUserDetailResponseDTO } from "@impacgroup/ayyildiz-checklist-platform-api-dtos";

export interface IPostcodeUserConfigService {
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const PostcodeUserServiceConfig = new InjectionToken<IPostcodeUserConfigService>("PostcodeUserConfig");

@Injectable()
export class PostcodeUsersService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(PostcodeUserServiceConfig) private postcodeUserConfig: IPostcodeUserConfigService, private postcodeUsersRepository: PostcodeUsersRepository) {
        this.UTCDATEFORMAT = this.postcodeUserConfig.utcDateFormat;
        this.datatableStateSaveMode = this.postcodeUserConfig.datatableStateSaveMode;
    }

    public list(params: IApiListQueryParameter): Observable<{ list: PostcodeUser[]; count: number; total: number }> {
        return this.postcodeUsersRepository.list(params).pipe(
            map((result) => {
                return {
                    list: result.list.map((dto) => {
                        return { ...dto };
                    }),
                    count: result.count,
                    total: result.total
                };
            })
        );
    }

    public read(userId: string, params: IApiListQueryParameter): Observable<PostcodeUserDetail> {
        return this.postcodeUsersRepository.read(userId, params).pipe(this.covertPostcodeUserDetailDTOtoViewModel());
    }

    public create(userId: string, postCodeId: string, params: IApiListQueryParameter): Observable<PostcodeUserDetail> {
        return this.postcodeUsersRepository.create(userId, postCodeId, params).pipe(this.covertPostcodeUserDetailDTOtoViewModel());
    }

    public delete(userId: string, postCodeId: string, params: IApiListQueryParameter): Observable<PostcodeUserDetail> {
        return this.postcodeUsersRepository.delete(userId, postCodeId, params).pipe(this.covertPostcodeUserDetailDTOtoViewModel());
    }

    private covertPostcodeUserDetailDTOtoViewModel(): OperatorFunction<{ details: PostcodeUserDetailResponseDTO; count: number; total: number }, PostcodeUserDetail> {
        return map((result) => {
            return {
                userDetails: result.details.userDetails,
                assignedPostcodes: result.details.assignedPostcodes,
                unassignedPostcodes: result.details.unassignedPostcodes,
                countUnassignedPostcodes: result.count,
                totalUnassignedPostcodes: result.total
            };
        });
    }
}
