import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PostcodeUsersService } from "./postcodeusers.service";
import { BaseDatatableComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { PostcodeUser } from "../../api-models/PostcodeUser";
import { PostcodeUsersMainComponent } from "./postcodeusers.main.component";

@Component({
    selector: "app-postcodeusers",
    templateUrl: "./postcodeusers.component.html",
    styleUrls: ["postcodeusers.component.scss"]
})
export class PostcodeUsersComponent extends BaseDatatableComponent {
    public UTCDATEFORMAT: string = "";

    rows: PostcodeUser[] = [];

    constructor(private postcodeUsersService: PostcodeUsersService, private translateService: TranslateService, private router: Router, private route: ActivatedRoute, parent: PostcodeUsersMainComponent) {
        super(translateService, {
            stateSaveMode: postcodeUsersService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_postcodeusers_list"
        });

        this.dtOptions.order = [[3, "desc"]];
        this.UTCDATEFORMAT = this.postcodeUsersService.UTCDATEFORMAT;
    }

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.postcodeUsersService.list(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "firstname", orderable: true },
            { data: "lastname", orderable: true },
            { data: "email", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    showUserPostcodes(id: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }
}
